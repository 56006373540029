import React, {useContext} from "react";
import {Context} from "redux/Store";
import {Link} from "gatsby";
import {Row, Col} from "react-grid-system";
import logoEucerin from "images/eucerin-logo.png";
import logoBeirsdorf from "images/beirsdorf-logo.webp";

function Footer({jobCode}) {
    const {state, dispatch} = useContext(Context);

    return (
        <footer>
            <div className="inner-centered-container">
                <div className="logo-block">
                    <Row align="center" justify="center">
                        <Col sm={12} md={4} id="logos-block">
                            <div className="logos">
                                <Link to="https://www.eucerinus.com/" target="_blank">
                                    <img
                                        src={logoEucerin}
                                        alt="Eucerin logo"
                                        className="logo-eucerin"
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col sm={12} md={4} id="copyright-text-block">
                            <p className="copyright-text">
                                &copy; Beiersdorf 2023
                                <br/>
                                BDF367
                            </p>
                        </Col>
                        <Col sm={12} md={4} id="beirsdorf-logo-div">
                            <div className="beirsdorf-logo-div">
                                <Link to="https://www.beiersdorfusa.com/" target="_blank">
                                    <img
                                        src={logoBeirsdorf}
                                        alt="Beirsdorf Logo"
                                        className="logo-beirsdorf"
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
