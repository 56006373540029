import React from "react";
import {Helmet} from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import favIcon from "images/favicon.png";
import "scss/styles.scss";

function Layout({pageClass, hero, jobCode, children}) {
    return (
        <>
            <Helmet>
                <link rel="icon" href={favIcon}/>
            </Helmet>
            <div className={`${pageClass ? pageClass : ""} page-container`}>
                <div className="main-content">
                    <Header/>
                    <main>
                        {hero && <div className="hero-wrapper">{hero}</div>}
                        <div className="inner-centered-container">
                        </div>
                        <div className="body-content-wrapper">{children}</div>
                    </main>
                    <Footer jobCode={jobCode}/>
                </div>
            </div>
        </>
    );
}

export default Layout;
