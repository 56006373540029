import React, { useContext } from "react";
import { Link } from "gatsby";
import { Context } from "redux/Store";
import logoEucerin from "../../images/eucerin-header-logo.png";


function Header() {
    const { state, dispatch } = useContext(Context);

    return (
        <header>
            <div className="utility-bar-bg">
                &nbsp;
            </div>
            <div className="logo-nav-bar-bg">
                <div className="inner-centered-container">
                    <div className="logo-nav-bar">
                        <div className="logos">
                            <a href="https://www.eucerinus.com/" target="_blank">
                                <img
                                    src={logoEucerin}
                                    alt="Eucerin logo"
                                    className="logo-eucerin"
                                />
                            </a>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
